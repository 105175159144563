import axios from "axios";
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		tags: [],
		selfListingsResponse: null,
	},
	mutations: {
		setTags(state, tags) {
			Vue.set(state, 'tags', tags);
		},

		setSelfListingsResponse(state, tags) {
			Vue.set(state, 'selfListingsResponse', tags);
		}
	},
	getters: {
		tags(state) {
			return state.tags;
		},

		getSelfListingsResponse(state) {
			return state.selfListingsResponse;
		},

		getSelfListingsMerchant(state) {
			if (state.selfListingsResponse === null) {
				return null;
			}

			return state.selfListingsResponse.results.filter((item) => {
				return item.listingType === "merchant";
			});
		},

		getSelfListingById(state) {
			return ({ id }) => {
				if (state.selfListingsResponse === null) {
					return null;
				}

				return state.selfListingsResponse.results.find((item) => {
					return item.id === id;
				});
			}
		}
	},
	actions: {
		/**
		 * Request a set of listings by their listing ids
		 * @param ctx
		 * @param ids
		 */
		requestPreviewListings(ctx, ids) {
			return axios.post("/api/v1/preview/search", { listings: ids });
		},

		/**
		 * Request a set of listings by their listing ids
		 * @param ctx
		 * @param ids
		 * @param start
		 * @param size
		 */
		requestListingsByIds(ctx, { ids, start = 0, size = 12 }) {
			return axios.post(`/api/v1/item/search?start=${start}&size=${size}`, {
				ids: ids,
			});
		},

		/**
		 * Request All listings
		 * * @param ctx 
		 * @param type The listing type [property|event|classified|merchant|item]
		 * @param start
		 * @param size
		 * @returns {Promise<*>}
		 */
		async requestAllListings(ctx, { type, start = 0, size = 15 }) {
			return axios.get(`/api/v1/${type}/listings`, {
				params: {
					start,
					size
				}
			});
		},
		async searchListings(ctx, { query,type, start = 0, size = 15 }) {
			Object.keys(query).forEach(key => {
                if (query[key] == null)
                    delete query[key];
            });

			return axios.post(`/api/v1/${type}/search`, query, {
				params: {
					start,
					size
				}
			});
		},
		async genericSearchListings(ctx, { request, start = 0, size = 15 }){
			return axios.post(`/api/v1/listing/search`, request, {
				params: {
					start,
					size
				}
			});
		},

		/**
		 * Creates a listing based on the given type.
		 * * @param ctx The vuex commit context.
		 * @param type The listing type [property|event|classified|merchant|item]
		 * @param listing The listing DTO.
		 * @returns {Promise<*>}
		 */
		async createListing(ctx, { type, listing }) {
			return axios.post(`/api/v1/${type}/listing`, listing);
		},
		/**
		 * Issues a modification request for the given listing based on type and ID.
		 * @param ctx The vuex commit context.
		 * @param type The listing type [property|event|classified|merchant|item]
		 * @param listing The listing DTO. This is declarative, so the entire listing data structure will be replaced by what was passed in.
		 * @param id {Number} The listing ID to modify.
		 * @returns {Promise<*>}
		 */
		async modifyListing(ctx, { type, listing, id }) {
			return axios.put(`/api/v1/${type}/listing/${id}`, listing);
		},
		/**
		 * Gets a listing from the remote server based on the given type and ID. This listing will be committed to memory, as well as returned from the promise.
		 * @param commit The vuex commit context.
		 * @param type The listing type [property|event|classified|merchant|item]
		 * @param id {Number} The listing ID being requested.
		 * @returns {Promise<* | null | string>} The listing being requested, null if the listing doesnt exist, an error elsewise.
		 */
		async requestListing(_, { type, id }) {
			return axios.get(`/api/v1/${type}/listing/${id}`).then(response => {
				if (response.status === 200) {
					return response.data;
				} else {
					return null;
				}
			});
		},

		async requestMerchantById(ctx, { id }) {
			return this.dispatch('api/listings/requestListing', {
				type: 'merchant',
				id: id,
			});
		},

		requestMerchantsByIds(v, { ids, start = 0, size = 12 }) {
			return axios.post(`/api/v1/merchant/search?start=${start}&size=${size}`,{
				ids: ids,
			});
		},

		async deleteListing(ctx, { type, id }) {
			return axios.delete(`/api/v1/${type}/listing/${id}`);
		},

		async requestListingTags({ commit }) {
			return axios.post('/api/v1/tag/all').then(r => {
				commit('setTags', r.data);
				return r;
			});
		},

		async requestSelfListingsAll() {
			return this.dispatch('api/listings/requestSelfListings', {
				start: 0,
				size: 9999,
				title: null,
			});
		},

		async requestSelfListings({ commit }, {
			start = 0,
			size = 15,
			title = null,
		}) {
			let query = new URLSearchParams({
				start,
				size,
				title
			}).toString();

			return axios.post(`/api/v1/preview/self?${query}`).then((r) => {
				commit('setSelfListingsResponse', r.data)
			});
		},

		async getHistoricListing(ctx, { type, id }){
			return axios.get(`/api/v1/${type}/listing/${id}/revisions`);
		},

		async getRevisions(ctx, { type, id, revision }){
			return axios.get(`/api/v1/${type}/listing/${id}/revisions/${revision}`);
		},
	},
};
